(function () {
  'use strict';

  /* @ngdoc object
   * @name dashboard.learning
   * @description
   *
   */
  angular
    .module('dashboard.learning', [
      'ui.router',
      'learning.editModule',
      'learning.editResource',
      'learning.module',
      'learning.resource',
      'learning.index',
      'learning.moduleSet',
      'learning.moduleSetEdit'
    ]);
}());
